import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

const API = environment.api;

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) {}

  list(query = {}) {
    return new Promise((resolve) => {
      const params = new HttpParams({ fromObject: query });
      this.http.get(`${API}/usuarios`, { params })
      .subscribe(
        (response: any) => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  get(id) {
    return new Promise((resolve) => {
      this.http.get(`${API}/usuarios/${id}`)
      .subscribe(
        (response: any) => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  count(query = {}) {
    return new Promise((resolve) => {
      const params = new HttpParams({ fromObject: query });
      this.http.get(`${API}/usuarios-activos`, { params })
      .subscribe(
        (response: any) => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  repeatLastPayment(data) {
    return new Promise((resolve) => {
      this.http.post(`${API}/usuarios/${data.userId}/repeatLastPayment`, data)
      .subscribe(
        (response: any) => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  update(data) {
    const formData = new FormData();

    for (let key in data) {
      if (key != 'imagen') { formData.append(key, data[key]); }
      else if (data[key]) { formData.append(key, data[key], 'profile'); }
    }

    formData.append('_method', 'PUT');
    return new Promise((resolve) => {
      this.http.post(`${API}/usuarios/${data.id}`, formData).subscribe(
        (response: any) => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  delete(id) {
    return new Promise((resolve) => {
      this.http.delete(`${API}/usuarios/${id}`).subscribe(
        (response: any) => resolve(response),
        (error) => resolve(error)
      );
    });
  }
}
